import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollbarsRef } from "../../App";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        scrollbarsRef.current?.scrollToTop();
        // window.scrollTo(0,0);
    }, [pathname]);
    return null
}