import React, { ComponentClass, createRef, Suspense } from "react";
import TopHeader, { ITopHeaderProps } from "./components/TopHeader";
import { ConfigProvider, Layout } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import {
  positionValues,
  ScrollbarProps,
  Scrollbars,
} from "react-custom-scrollbars";
import "./App.css";
import { ConfigProviderProps } from "antd/lib/config-provider";
import { IScreenSize, screenSizes } from "./components/Screens";
import {
  Redirect,
  Route,
  RouteComponentProps,
  HashRouter,
  Switch,
  withRouter,
  Router,
  BrowserRouter,
  RouterProps,
} from "react-router-dom";
import { Routers } from "./routers/routers";
import BootomFooter from "./components/Footer";
import Footer from "./components/Footer";
import background from './asset/img/background.png'
import ScrollToTop from "./components/ScrollToTop";

interface IAppProps{}
interface IAppState {
  providerConfig: ConfigProviderProps;
  ScrollbarConfig: ScrollbarProps;
  topHeaderConfig: ITopHeaderProps;
}
export const scrollbarsRef = createRef<Scrollbars>()
export class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    if(window.screen && window.screen.height && window.screen.width && window.screen.height > window.screen.width) {
      window.location.href = 'http://h5.xzyby.cn/'
    }
    const that = this;
    this.state = {
      providerConfig: {
        locale: zhCN,
      },
      topHeaderConfig: {
        scrollMore: false,
      },
      ScrollbarConfig: {
        autoHeight: true,
        autoHeightMin: "100vh",
        autoHeightMax: "100vh",
        autoHide: true,
        autoHideTimeout: 300,
        style: {
          width: "100%",
          height: "100%",
        },
        onScrollFrame: (positionValues: positionValues) => {},
      },
    };
  }
  render() {
    const { providerConfig, ScrollbarConfig, topHeaderConfig } = this.state;
    return (
      <ConfigProvider {...providerConfig}>
        {
          window.location.href === 'https://www.xzyby.cn/tolink'? 
          <BrowserRouter>
            <Switch>
              <Route path='/tolink' render={() => {
                return (<div></div>)
              }}
              exact={true}/>
            </Switch>
          </BrowserRouter>: 
          <HashRouter>
            <Layout className="App">
              <TopHeader {...topHeaderConfig} />
              <div className="scrollSection" style={{background: `url(${background}) no-repeat`,backgroundSize: '100%,100%'}}>
                <Scrollbars id="handelDocID" {...ScrollbarConfig} ref={scrollbarsRef}>
                  {/* 使用了路由懒加载，所以需要使用<Suspense>包起来 */}
                  <Suspense fallback={<div></div>}>
                    <Switch>
                      {Routers.map((router) => (
                        <Route
                          exact={!router.notExect}
                          key={router.key}
                          path={router.path}
                        ><ScrollToTop/><router.component/></Route>
                      ))}
                      <Redirect path="/" to="/home" />
                    </Switch>
                  </Suspense>
                  <Footer />
                </Scrollbars>
              </div>
            </Layout>
          </HashRouter>
        }
      </ConfigProvider>
    );
  }
}

export default App;