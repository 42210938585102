import React, { Component } from "react";
import { Layout } from "antd";
import "./css/index.css";
import appDownload from "../../asset/foot/app_download.png";
import douyin from "../../asset/foot/douyin.png";
import weibo from "../../asset/foot/weibo.png";
import weixin from "../../asset/foot/weixin.jpg";
import xiaochengxu from "../../asset/foot/xiaochengxu.jpg";
import background from "./img/background.png";

import jinghui from "../../asset/foot/jinghui.png";

const { Footer } = Layout;
export default class BootomFooter extends Component {
  render() {
    return (
      <Footer className="footerStyle">
        <div
          className="topInfo"
          style={{
            background: "url('" + background + "') no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "100%",
          }}
        >
          <div className="leftText">
            版权所有：徐州新盛园博园建设发展有限公司 <br />
            地址：江苏省徐州市铜山区伊庄镇
            <br />
            邮箱：18052281195@189.cn
            <br />
            {/* 投诉电话：88888888<br />
          游客服务电话：88888888<br /> */}
            {/* <br />
          <br />
          版权所有：航天光电科技发展（天津）有限公司 <br />
          地址：天津市南开区黄河道与广开四马路交口格调大厦11楼<br />
          投诉电话：022-87385928<br /> */}
          </div>

          <div className="qrcodeContainer">
          <div className="qrItemContainer">
              <img src={douyin} alt="" />
              <span className="qrTitle">抖音</span>
            </div>
            <div className="qrItemContainer">
              <img src={weixin} alt="" />
              <span className="qrTitle">公众号</span>
            </div>
            <div className="qrItemContainer">
              <img src={weibo} alt="" />
              <span className="qrTitle">官方微博</span>
            </div>
            <div className="qrItemContainer">
              <img src={xiaochengxu} alt="" />
              <span className="qrTitle">小程序</span>
            </div>
          {/* <div className="qrItemContainer">
              <img src={appDownload} alt="" />
              <span className="qrTitle">APP下载</span>
            </div> */}
          </div>
          {/* <div className="rightText">
          扫黑除恶专项斗争举报渠道 <br />
          举报电话：XXX-12389 63811992<br />
          举报邮箱：XXXXX.@163.com<br />
          举报信箱：XXXXXXX号信箱<br />
          举报网站：www.12389.gov.cn  www.ftjj.gov.cn<br />
          </div> */}
        </div>
        <div className="bottomInfo">
          <span style={{ color: "#fff" }}>
            Copyright©2021 徐州新盛园博园建设发展有限公司{" "}
          </span>
          <span style={{ color: "#049342" }}>隐私声明</span>
          <span style={{ color: "#777777" }}>|</span>
          <span style={{color: "#049342", cursor: 'pointer'}} onClick={() => {
            window.open('https://beian.miit.gov.cn/');
          }}>
            使用条款 苏ICP备2021031450号-1
          </span>
          <span style={{ color: "#777777" }}>|</span>
          <img src={jinghui} alt="" />
          <span> </span>
          <span style={{color: "#049342", cursor: 'pointer'}} onClick={() => {
            window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32031202000756');
          }}>
            苏公网安备 32031202000756号
          </span>
        </div>
      </Footer>
    );
  }
}
