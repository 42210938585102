import { ModifyAction } from "../actions";
import { LOGIN, SIGNOUT } from "../constants";
import { LOGINSTATE } from "../enmu";
import { LoginInfo } from "../type";

var signoutTimeouter: any;
var loginInfo: LoginInfo = {
  state:
    localStorage.getItem("state") !== null
      ? (localStorage.getItem("state") as LOGINSTATE)
      : LOGINSTATE.SIGNOUT,
  userInfo:
    localStorage.getItem("user_info") !== null
      ? JSON.parse(localStorage.getItem("user_info") as string)
      : {},
  userToken:
    localStorage.getItem("userToken") !== null
      ? (localStorage.getItem("userToken") as string)
      : "",
};

// 处理并返回 state
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = loginInfo, action: ModifyAction): LoginInfo => {
  switch (action.type) {
    case LOGIN:
      // 登录成功后，将token存储到localStorage中
      localStorage.setItem("state", LOGINSTATE.SIGNIN);
      localStorage.setItem("userToken", action.userToken);
      localStorage.setItem("user_info", JSON.stringify(action.userInfo));
      // 登录方法
      state = {
        state: LOGINSTATE.SIGNIN,
        userInfo: action.userInfo,
      };
      // 清除登陆状态计时器
      clearTimeout(signoutTimeouter);
      // 计时器 30分钟后退出登录
      signoutTimeouter = setTimeout(() => {
        state = {
          state: LOGINSTATE.SIGNOUT,
          userToken: "",
        };
      }, 30 * 60 * 1000);
      return state;
    case SIGNOUT:
      state = {
        state: LOGINSTATE.SIGNOUT,
        userToken: "",
      };
      return state;
    default:
      return state;
  }
};
