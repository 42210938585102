import { LOGIN, SIGNOUT } from "../constants";
import { UserInfo } from "../type";

export interface ILOGIN {
  type: LOGIN;
  userInfo: UserInfo;
  userToken: string;
}

export interface ISIGNOUT {
  type: SIGNOUT;
}

// 定义 modifyAction 类型，包含 IINCREMENTAction 和 IDECREMENTAction 接口类型
export type ModifyAction = ILOGIN | ISIGNOUT;

// 登录
export const login = (userInfo: UserInfo, userToken: string): ILOGIN => ({
  type: LOGIN,
  userInfo: userInfo,
  userToken: userToken,
});

// 注销
export const signout = (): ISIGNOUT => ({
  type: SIGNOUT,
});
